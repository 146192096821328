import React from 'react';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Container, Row } from 'react-bootstrap';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import BlogHeader from '../components/BlogHeader/BlogHeader';
import BlogCards from '../components/BlogCards/BlogCards';

import './blog.css';

const BlogPage = ({ data }) => {
  const posts = data.posts.nodes;
  const content = data.content.frontmatter;

  if (!posts?.length) {
    return (
      <Layout>
        <SEO title={content.meta.seo_title} description={content.meta.seo_description} />

        <Container className="blog-error-container" fluid>
          <h1 className="section-headline" dangerouslySetInnerHTML={{ __html: content.hero.headline }} />
          <p className="mt-5 mb-5">
            <FormattedMessage id="pages.blog.error" />
          </p>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO title={content.meta.seo_title} description={content.meta.seo_description} />

      <BlogHeader content={content} posts={posts} />
      <Container fluid>
        <div className="blog-cards-container">
          <Row>
            <BlogCards posts={posts} noFilter={false} />
          </Row>
        </div>
      </Container>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query($language: String!) {
    content: markdownRemark(
      collection: { eq: "pages" }
      folder: { eq: "blog" }
      fields: { language: { eq: $language } }
    ) {
      frontmatter {
        meta {
          name
          slug
          seo_title
          seo_description
        }
        hero {
          headline
          text
          image {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
            extension
          }
          image_tablet {
            childImageSharp {
              fluid(maxWidth: 480) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
            extension
          }
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { collection: { eq: "blog" }, fields: { published: { eq: true }, language: { eq: $language } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt(pruneLength: 270)
        fields {
          slug
        }
        frontmatter {
          image {
            childImageSharp {
              fluid(maxWidth: 960, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          abstract
          dateDe: date(formatString: "DD. MMMM YYYY", locale: "de_DE")
          dateEn: date(formatString: "MMMM DD, YYYY", locale: "en_US")
          author
        }
      }
    }
  }
`;
