import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Img from 'gatsby-image';
import { FormattedMessage, useIntl, Link as LinkIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import posed from 'react-pose';

import * as classes from './BlogHeader.module.css';

const HoverBox = posed.div({
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.02,
    transition: { duration: 500 },
  },
});

const BlogHeader = (props) => {
  const intl = useIntl();

  if (!props.posts || !props.posts?.length) {
    return null;
  }

  const firstPost = props.posts[0];

  return (
    <section id="blog-header" className={classes.BlogHeader}>
      <Container className={classes.BlogHeaderContainer} fluid>
        <h1 className="section-headline" dangerouslySetInnerHTML={{ __html: props.content.hero.headline }} />
        {props.content?.hero?.text && (
          <p className="section-headline-small" dangerouslySetInnerHTML={{ __html: props.content.hero.text }} />
        )}

        <div className={classes.BlogHeaderRow}>
          <HoverBox>
            <Row>
              <Col lg={6}>
                <div className={classes.BlogImage}>
                  <LinkIntl to={`/blog/${firstPost.fields.slug}/index.html`}>
                    <Img
                      fluid={
                        props.content.frontmatter?.hero?.image?.childImageSharp?.fluid ??
                        firstPost.frontmatter.image.childImageSharp.fluid
                      }
                      alt={firstPost.frontmatter.title}
                      style={{ borderRadius: '8px', maxHeight: '380px' }}
                    />
                  </LinkIntl>
                </div>
                <div className={classes.BlogImageDecoration} />
              </Col>
              <Col lg={6}>
                <div className={classes.BlogDetails}>
                  <LinkIntl to={`/blog/${firstPost.fields.slug}/index.html`}>
                    <h2
                      className={classes.BlogTitle}
                      dangerouslySetInnerHTML={{ __html: firstPost.frontmatter.title }}
                    />
                    <p className={classes.BlogDate}>
                      {intl.locale === 'de' ? firstPost.frontmatter.dateDe : firstPost.frontmatter.dateEn}
                    </p>
                    <p className={classes.BlogAbstract}>{firstPost.frontmatter.abstract || firstPost.abstract}</p>
                    <Button variant="link" className={classes.BlogButton}>
                      <FormattedMessage id="buttons.learn_more" />
                      <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '.7rem' }} />
                    </Button>
                  </LinkIntl>
                </div>
              </Col>
            </Row>
          </HoverBox>
        </div>
      </Container>
    </section>
  );
};

BlogHeader.propTypes = {
  content: PropTypes.array.isRequired,
  posts: PropTypes.array.isRequired,
};

export default BlogHeader;
